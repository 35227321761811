.img-banner{
    border: solid white 1px;
}
@media screen and (max-width: 640px) {

    .img-banner{
        width: 100%;
        height: auto;
    }
}
@media screen and (min-width: 640px) {

    .img-banner{
        width: 100%;
        height: 10rem;
        object-fit: cover;
    }
}

@media screen and (min-width: 992px) {
    .img-banner{
        width: 100%;
        height: auto;
    }
}