@media screen and (max-width: 640px) {
    .img-title{
        height: 1.5rem;
        width: auto;
        max-width: 85%;
    }

    .divTextPostTitle {
        /*text-align: justify;*/
        color: white;
        font-size: large;
        font-weight: 400;
    }
}

@media screen and (min-width: 640px) {
    .img-title{
        height: 1.5rem;
        width: auto;
        max-width: 85%;
    }
    
        .divTextPostTitle {
            /*text-align: justify;*/
            color: white;
            font-size: large;
            font-weight: 400;
        }
}

@media screen and (min-width: 1280px) {
    .img-title{
        height: 95%;
    }

    .divTextPostTitle {
        /*text-align: justify;*/
        color: white;
        font-size: x-large;
        font-weight: 400;
    }
}