.courseClasses-H3{
    color: white;
}


@media screen and (max-width: 640px) {

    .courseClasses-H3{
        font-size: 1rem;
        color: white;
    }
}
@media screen and (min-width: 640px) {

    .courseClasses-H3{
        font-size: 2rem;
        color: white;
    }
}

@media screen and (min-width: 992px) {
    .courseClasses-H3{
        font-size: 3rem;
        color: white;
    }
}