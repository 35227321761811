
@media screen and (max-width: 640px) {   
    .socialMediaFoto{
        height: 7rem;
        width: 100%;
        object-fit: cover;        
    }
}

@media screen and (min-width: 640px) and (max-width: 1280px) {
    
    .socialMediaFoto{
        height: 7rem;
        width: 100%;
        object-fit: cover;
        
    }
}

@media screen and (min-width: 1280px) {
    .facebookBackground{
        background-image: url("../../../public/img/facebookPlugin.png");
        background-repeat: no-repeat;
        background-size: 100% 95%;
    }
    .socialMediaFoto{
        height: 8rem;
        width: 100%;
        object-fit: cover;        
    }
   
}