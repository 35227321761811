
@media screen and (max-width: 640px) {
    .moduleCourse-img{
        max-width: 60%;
    }
    
}

@media screen and (min-width: 640px) and (max-width: 1280px) {
    .moduleCourse-img{
        max-width: 60%;
    }
  
}

@media screen and (min-width: 1280px) {
    .moduleCourse-img{
        max-width: 60%;
    }
    
}
