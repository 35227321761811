@media screen and (max-width: 640px) {    
    
}

@media screen and (min-width: 640px) and (max-width: 1280px) {
   
    
}

@media screen and (min-width: 1280px) {
  
    .courses-div{
        padding-left: 15%;
        padding-right: 15%;
    }

}
